import React, { PureComponent } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { ClipLoader } from 'react-spinners';
import 'react-datepicker/dist/react-datepicker.css';
import {
  TourSearchCard,
  FilterTag,
  NoResultsFound,
  FooterBanner,
} from 'components';
import { Layout } from 'containers';
import moment from 'moment';
import { REQUEST, RESPONSE_HANDLER } from 'utilities';
import { VideoIntro } from 'layouts';

let searchParams = {};

const LOCATION_OPTIONS = [
  { value: 'all-new-zealand', label: 'All New Zealand' },
  { value: 'north-island', label: 'North Island' },
  { value: 'south-island', label: 'South Island' },
];

const DURATION_OPTIONS = [
  { value: 'all', label: 'All durations' },
  { value: '1-7', label: '1 - 7 days' },
  { value: '8-14', label: '8 - 14 days' },
  { value: '15-100', label: '15+ days' },
];

const STYLE_OPTIONS = [
  { value: 'all', label: 'All styles' },
  { value: 'adventure', label: 'Adventure' },
  { value: 'plus', label: 'Plus' },
  { value: 'snow', label: 'Snow' },
];

const customStyles = {
  control: provided => ({
    ...provided,
    height: '48px',
    width: '525px',
    border: '1px solid #c2c4c7',
    borderRadius: 0,
    padding: '0 15px',
    boxShadow: 'unset',
    textAlign: 'left',
    '@media only screen and (max-width: 992px)': {
      ...provided['@media only screen and (max-width: 992px)'],
      width: '100%',
    },
  }),

  menuList: provided => ({
    ...provided,
    textAlign: 'left',
  }),
};

const updateParam = (key, value) => {
  const isBrowser = () => typeof window !== 'undefined';
  const queryParams = isBrowser() ? new URLSearchParams(window.location.search) : null;
  queryParams.set(key, value);

  window.history.pushState({}, '', `/tours-search?${queryParams.toString()}`);
};

const deleteParam = (key) => {
  const isBrowser = () => typeof window !== 'undefined';
  const queryParams = isBrowser() ? new URLSearchParams(window.location.search) : null;
  queryParams.delete(key);

  window.history.pushState({}, '', `/tours-search?${queryParams.toString()}`);
};

const dateIsValid = (value) => {
  if (value == null) return false;
  const date = new Date(value);

  return date instanceof Date && !Number.isNaN(Number(date));
};

const SEARCH_TOURS = async () => {
  const SEARCH = await REQUEST.post('tour_info/tours/search', {
    json: {
      search: searchParams,
    },
  });
  return RESPONSE_HANDLER(SEARCH);
};

class ToursSearchPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocation: null,
      selectedDateRangeFrom: null,
      selectedDateRangeTo: null,
      selectedDuration: null,
      selectedStyle: null,
      onSale: false,
      list: [],
      isCollapsed: true,
      filterTags: [],
      loading: false,
    };

    this.onSelectLocation = this.onSelectLocation.bind(this);
    this.onDateRangeFromChanged = this.onDateRangeFromChanged.bind(this);
    this.onDateRangeToChanged = this.onDateRangeToChanged.bind(this);
    this.onDurationChanged = this.onDurationChanged.bind(this);
    this.onStyleChanged = this.onStyleChanged.bind(this);
    this.onClearDates = this.onClearDates.bind(this);
    this.onSaleChanged = this.onSaleChanged.bind(this);
    this.updateList = this.updateList.bind(this);
    this.updateFilterTags = this.updateFilterTags.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.removeAllFilters = this.removeAllFilters.bind(this);
  }

  componentWillMount() {
    searchParams = {};
    const isBrowser = () => typeof window !== 'undefined';
    const queryParams = isBrowser() ? new URLSearchParams(window.location.search) : null;

    if (queryParams) {
      const locationIndex = LOCATION_OPTIONS.map(option => option.value).indexOf(queryParams.get('location'));
      const durationIndex = DURATION_OPTIONS.map(option => option.value).indexOf(queryParams.get('duration'));
      const styleIndex = STYLE_OPTIONS.map(option => option.value).indexOf(queryParams.get('style'));

      const startFrom = dateIsValid(queryParams.get('date_range_from')) ? new Date(queryParams.get('date_range_from')) : null;
      const endAt = dateIsValid(queryParams.get('date_range_to')) ? new Date(queryParams.get('date_range_to')) : null;
      const duration = DURATION_OPTIONS[durationIndex > 0 ? durationIndex : 0].value;
      const style = STYLE_OPTIONS[styleIndex > 0 ? styleIndex : 0].value;
      const onSale = (queryParams.get('onSale') === 'true' || queryParams.get('on_sale') === 'false') ? queryParams.get('onSale') : false;

      this.setState({ selectedLocation: LOCATION_OPTIONS[locationIndex > 0 ? locationIndex : 0] });
      this.setState({ selectedDateRangeFrom: startFrom });
      this.setState({ selectedDateRangeTo: endAt });
      this.setState({ selectedDuration: duration });
      this.setState({ selectedStyle: style });
      this.setState({ onSale });

      searchParams.location = LOCATION_OPTIONS[locationIndex > 0 ? locationIndex : 0].label;
      searchParams.duration = duration;
      searchParams.style = style;
      searchParams.on_sale = onSale;
      searchParams.start_from = startFrom ? moment(startFrom).format('DD/MM/yyyy') : null;
      searchParams.end_at = endAt ? moment(endAt).format('DD/MM/yyyy') : null;

      this.updateList();
    }
  }

  onSelectLocation(e) {
    this.setState({ selectedLocation: e });
    updateParam('location', e.value);
    searchParams.location = e.label;

    this.updateList();
  }

  onDateRangeFromChanged(e) {
    if (e === null) return;
    this.setState({ selectedDateRangeFrom: e });
    updateParam('date_range_from', e.toLocaleDateString('en-US'));
    searchParams.start_from = moment(e).format('DD/MM/yyyy');

    this.updateList();
  }

  onDateRangeToChanged(e) {
    if (e === null) return;
    this.setState({ selectedDateRangeTo: e });
    updateParam('date_range_to', e.toLocaleDateString('en-US'));
    searchParams.end_at = moment(e).format('DD/MM/yyyy');

    this.updateList();
  }

  onClearDates() {
    this.setState({ selectedDateRangeFrom: null });
    this.setState({ selectedDateRangeTo: null });
    deleteParam('date_range_from');
    deleteParam('date_range_to');
    searchParams.start_from = null;
    searchParams.end_at = null;

    this.updateList();
  }

  onDurationChanged(e) {
    const { target: { value } } = e;
    this.setState({ selectedDuration: value });
    updateParam('duration', value);
    searchParams.duration = value;

    this.updateList();
  }

  onStyleChanged(e) {
    const { target: { value } } = e;
    this.setState({ selectedStyle: value });
    updateParam('style', value);
    searchParams.style = value;

    this.updateList();
  }

  onSaleChanged(e) {
    const value = e.target.value === 'false';
    this.setState({ onSale: value });
    if (value) {
      updateParam('onSale', value);
    } else {
      deleteParam('onSale');
    }
    searchParams.on_sale = value;

    this.updateList();
  }

  updateList() {
    this.setState({
      list: [],
      loading: true,
    }, async () => {
      this.setState({ list: await SEARCH_TOURS() });
      this.updateFilterTags();
      this.setState({ loading: false });
    });
  }

  updateFilterTags() {
    const {
      selectedDateRangeFrom,
      selectedDateRangeTo,
      selectedDuration,
      selectedStyle,
      onSale,
    } = this.state;

    const list = [];
    if (selectedDateRangeFrom) list.push({ key: 'date_start_from', label: `Departing After ${moment(selectedDateRangeFrom).format('D MMM, YYYY')}` });
    if (selectedDateRangeTo) list.push({ key: 'date_end_at', label: `Finishing Before ${moment(selectedDateRangeTo).format('D MMM, YYYY')}` });
    if (selectedDuration && selectedDuration !== 'all') list.push({ key: 'duration', label: `${selectedDuration} days` });
    if (selectedStyle && selectedStyle !== 'all') list.push({ key: 'style', label: selectedStyle });
    if (onSale) list.push({ key: 'on_sale', label: 'On Sale' });

    this.setState({ filterTags: list });
  }

  removeFilter(tag) {
    switch (tag) {
      case 'date_start_from':
        this.setState({ selectedDateRangeFrom: null });
        deleteParam('date_range_from');
        searchParams.start_from = null;
        break;
      case 'date_end_at':
        this.setState({ selectedDateRangeTo: null });
        deleteParam('date_range_to');
        searchParams.end_at = null;
        break;
      case 'duration':
        this.setState({ selectedDuration: DURATION_OPTIONS[0].value });
        deleteParam('duration');
        searchParams.duration = null;
        break;
      case 'style':
        this.setState({ selectedStyle: STYLE_OPTIONS[0].value });
        deleteParam('style');
        searchParams.style = null;
        break;
      case 'on_sale':
        this.setState({ onSale: false });
        deleteParam('onSale');
        searchParams.on_sale = null;
        break;
      default:
        break;
    }

    this.updateList();
  }

  removeAllFilters() {
    this.setState({
      selectedDateRangeFrom: null,
      selectedDateRangeTo: null,
      selectedDuration: DURATION_OPTIONS[0].value,
      selectedStyle: STYLE_OPTIONS[0].value,
      onSale: false,
    });

    deleteParam('date_range_from');
    deleteParam('date_range_to');
    deleteParam('duration');
    deleteParam('style');
    deleteParam('onSale');

    searchParams.start_from = null;
    searchParams.end_at = null;
    searchParams.duration = null;
    searchParams.style = null;
    searchParams.on_sale = null;

    this.updateList();
  }

  render() {
    const {
      selectedLocation,
      selectedDateRangeFrom,
      selectedDateRangeTo,
      selectedDuration,
      selectedStyle,
      onSale,
      list,
      isCollapsed,
      filterTags,
      loading,
    } = this.state;

    const tours = list.data;
    let totalTourCount = 0;

    if (tours) {
      totalTourCount = tours.length;
    }

    return (
      <Layout page="tours-search">
        <div className="l-container search-section">
          <form className="l-search-tours-form mb-1 location-search">
            <div className="l-form-group input-location">
              <Select
                styles={customStyles}
                options={LOCATION_OPTIONS}
                onChange={this.onSelectLocation}
                defaultValue={selectedLocation}
              />
            </div>
            <div className="l-form-group">
              <button className="c-button c-button--primary search-btn" type="submit">Search</button>
              <button
                className="c-button c-button--border open-filter-menu-btn"
                type="button"
                onClick={() => { this.setState({ isCollapsed: !isCollapsed }); }}
              >
                Filters
              </button>
            </div>
          </form>

          <div className="search-applied-filters">
            {filterTags.map(tag => <FilterTag key={tag.key} tag={tag} removeFilter={this.removeFilter} />)}
            {filterTags.length > 0 && (
              <button
                className="c-tour-filter-tag c-button--border"
                type="button"
                onClick={this.removeAllFilters}
              >
                clear all filters
              </button>
            )}
          </div>

          <div className="search-tours-count mb-2">
            <span role="status"><strong>{totalTourCount}</strong> tours found</span>
          </div>

          <div className="l-grid__wrapper mb-2">
            <div className={`l-grid__cell l-grid__cell--3-col search-filters ${isCollapsed ? 'collapsed' : ''}`}>
              <form className="l-search-tours-form">
                <div className="l-form-group w-100 mb-1">
                  <label className="l-form-group__label mb-1">
                    <span>Travel Dates</span>
                  </label>
                  <DatePicker
                    selected={selectedDateRangeFrom}
                    onChange={this.onDateRangeFromChanged}
                    placeholderText="Departing"
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <span data-cy="helper-text" className="field__help">Eg. 01 Jan 2021 </span>
                  <DatePicker
                    selected={selectedDateRangeTo}
                    onChange={this.onDateRangeToChanged}
                    placeholderText="Finishing"
                    minDate={selectedDateRangeFrom ? new Date(selectedDateRangeFrom + 1) : new Date()}
                    dateFormat="dd/MM/yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <span data-cy="helper-text" className="field__help">Eg. 01 Jan 2021 </span>
                  <div className="align--right">
                    <button
                      className="c-button c-button--border c-button--small"
                      type="button"
                      onClick={this.onClearDates}
                    >
                      Clear dates
                    </button>
                  </div>
                </div>
                <div className="l-form-group w-100 mb-1">
                  <label className="l-form-group__label">
                    <span>Duration</span>
                  </label>
                  <select
                    id="TourDuration"
                    name="TourDuration"
                    className="c-form-control c-form-control c-form-control--select"
                    onChange={this.onDurationChanged}
                    value={selectedDuration}
                  >
                    {DURATION_OPTIONS.map(option => <option value={option.value} key={option.value}>{option.label}</option>)}
                  </select>
                </div>
                <div className="l-form-group w-100 mb-1">
                  <label className="l-form-group__label">
                    <span>Styles</span>
                  </label>
                  <select
                    id="TourStyle"
                    name="TourStyle"
                    className="c-form-control c-form-control c-form-control--select"
                    onChange={this.onStyleChanged}
                    value={selectedStyle}
                  >
                    {STYLE_OPTIONS.map(option => <option value={option.value} key={option.value}>{option.label}</option>)}
                  </select>
                </div>
                <div className="l-form-group w-100 mb-1">
                  <label className="l-form-group__label">
                    <span>Travel Deals</span>
                  </label>
                  <div className="l-form-group--checkbox">
                    <input
                      id="TravelDeal"
                      name="TravelDeal"
                      type="checkbox"
                      className="c-form-control c-form-control--input c-form-control--checkbox"
                      onChange={this.onSaleChanged}
                      checked={onSale}
                      value={onSale}
                    />
                    <label htmlFor="TravelDeal" className="l-form-group__label">On sale</label>
                  </div>
                </div>
              </form>
            </div>
            <div className="l-grid__cell l-grid__cell--9-col tour-list">
              {loading && (
                <div className="loading-screen pt-5">
                  <ClipLoader
                    className="pt-3"
                    sizeUnit="px"
                    size={50}
                    color="#123abc"
                  />
                </div>
              )}
              {(!loading && tours && tours.length === 0) && (
                <NoResultsFound isDate={(selectedDateRangeFrom == null && selectedDateRangeTo == null)} />
              )}
              <div className="l-tour-list__grid">
                {(tours && tours.length > 0) && (
                  tours.map(tour => <TourSearchCard tour={tour} key={tour.id} />)
                )}
              </div>
            </div>
          </div>
        </div>

        <VideoIntro
          title="How our tours work"
          videoUrl="https://www.youtube.com/watch?v=ITSEGjWanWY&feature=youtu.be"
          hideReviews
          disableVideoPromo={false}
        >
          <h4 className="c-heading c-heading--h4">Step 1: SELECT YOUR BASE TOUR</h4>
          <p>
            Every tour includes: accommodation, transport, tour guide, breakfast and just a few key inclusions which we guarantee are not be missed!
          </p>
          <h4 className="c-heading c-heading--h4">STEP 2: CUSTOMISE YOUR TOUR</h4>
          <p>
            Customise your trip by adding a whole range of activities, experiences, accommodation upgrades, extensions and activity packs.
          </p>
          <h4 className="c-heading c-heading--h4">STEP 3: BOOK & MANAGE YOUR TOUR ONLINE</h4>
          <p>
            Secure your seat for a $99 deposit, then pay it off over time – the balance is due 60 days prior to departure. During this time you can add more activities or customise your tour.
          </p>
          <p>
            It’s that easy! If you have any questions at all, then please <a href="/contact-us/" target="_blank">chat with us</a>.
          </p>
        </VideoIntro>

        <FooterBanner />
      </Layout>
    );
  }
}

export default ToursSearchPage;
